<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/company`"
                title="Company"
                :columns="columns"
                routerpath="/setup/addcompany"
                :formOptions="formOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          },
          hidden: true
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Address',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Address'
          }
        },
        {
          label: 'City',
          field: 'city',
          filterOptions: {
            enabled: true,
            placeholder: 'Search City'
          }
        },
        {
          label: 'Pincode',
          field: 'pincode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pincode'
          }
        },
        {
          label: 'State',
          field: 'state',
          filterOptions: {
            enabled: true,
            placeholder: 'Search State'
          }
        },
        {
          label: 'GST No',
          field: 'gstno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search GST No'
          }
        },
        {
          label: 'CST No',
          field: 'cstno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search CST No'
          }
        },
        {
          label: 'Pan No',
          field: 'panno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pan No'
          }
        },
        {
          label: 'Service Tax No',
          field: 'servicetaxnumber',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Service Tax No'
          }
        },
        {
          label: 'Phone No',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Phone No'
          }
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email'
          }
        },
        {
          label: 'Website',
          field: 'website',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Website'
          }
        },
        // {
        //   label: 'Company Logo',
        //   field: 'logo',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search Company Logo'
        //   }
        // },

        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Company' : 'Add Company'}`,
        submitRouterPath: '/crm/company',
        gridForm: true,
        getEditValue: `${baseApi}/getCompanyById`,
        method: 'post',
        action: 'add',
        url: `${baseApi}/company`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Company Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter state',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Country',
            name: 'country',
            value: '',
            type: 'text',
            placeholder: 'Enter Country',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Pin Code',
            name: 'pincode',
            value: '',
            type: 'number',
            placeholder: 'Enter Pincode',
            class: 'col-md-6'
            // required: true,
            // rules:digits:6
          },
          {
            label: 'Financial Year',
            name: 'financialyear',
            value: '',
            type: 'number',
            placeholder: 'Enter Financial Year',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'GST No',
            name: 'gstno',
            value: '',
            type: 'number',
            placeholder: 'Enter GST No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'CST No',
            name: 'cstno',
            value: '',
            type: 'number',
            placeholder: 'Enter CST No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Pan No',
            name: 'panno',
            value: '',
            type: 'text',
            placeholder: 'Enter Pan No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Service Tax Number',
            name: 'servicetaxnumber',
            value: '',
            type: 'number',
            placeholder: 'Enter Service Tax Number',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Phone No',
            name: 'phoneno',
            value: '',
            type: 'number',
            placeholder: 'Enter Phone No',
            class: 'col-md-6',
            required: true,
            rules: 'digits:10'
          },
          {
            label: 'Email',
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Enter Email',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Website',
            name: 'website',
            value: '',
            type: 'text',
            placeholder: 'Enter Website',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Logo',
            name: 'logo',
            value: '',
            type: 'fileinput',
            placeholder: 'Select Logo',
            class: 'col-md-6'
            // required: true,
          }
        ]
      }
    }
  },

  mounted () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
